
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-screen {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #F5F7F9;
}

/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial, sans-serif;
  background-color: #f0f0f0;
  color: #06345E;

  overflow-y: auto;
}

.container {
  display: flex;
  height: 100vh;
  overflow: hidden; /* Hide overflow on the container */
}

/* Form Container Styles */
.form-container {
  padding-left: 0px;
  background-color: #fff;
  overflow-y: auto; /* Add vertical scrollbar */

  background-color: #fff;
  overflow-y: auto;
  /* background: green; */
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-width: max(min(20%,550px),360px); /* 550px is max, 350 is min */
  flex: 0 1 450px;
  

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.viewer-container{
  width: 100%;
  flex: 1;
}

.section {

  margin-bottom: 10px;
    border: 1px solid rgb(0 0 0 / 0%);
    background-color: rgb(0 0 0 / 1%);
    padding: 0px 5px;
    border-radius: 5px;
    background-color: #F5F7F9;
}

.section:hover{
  background-color: rgb(13 138 250 / 3%);

}

h2 {
  color: #06345E;
}

h2 span svg.delete {
  color: #d32f2f;
  font-size: large;
  cursor: pointer;
}

.form-group {
  margin-bottom: 10px;
}

label {
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  margin-bottom: 10px;
  display: inline-block;
}

input[type="number"],
input[type="text"],
select {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

button:disabled:hover,
button[disabled]:hover{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}


/* Preview Container Styles */
.preview-container {
  flex: 1;
  background-color: #f0f0f0;
  padding: 20px;
  overflow-y: auto; /* Add vertical scrollbar */
}

.preview {
  text-align: center;
}

.wall {
  width: 200px;
  height: 200px;
  background-color: white;
  border: 1px solid #ccc;
}

h2{

  
    display: flex;
    align-items: center;
    justify-content: space-between;


    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;

}

h2 span{
  display: flex;
  gap: 10px;
  align-items: center;
}

.group{
  display: flex;
    justify-content: space-between;
    gap: 5px;
}

.group > div{
  width: 100%;
}

.form-group{
  margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.buttonloader{
  display: flex;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 20px;
  
}

.switch{
  display: flex;
  justify-content: space-between;
  align-items: center;}

  .logoKoto{
    width: 24px;
  }

  h1.titleform{
display: flex;
align-content: center;
align-items: center;
justify-content: flex-start;
gap: 14px;
color: #06345E;

padding-left: 28px;  }

  .MuiStepLabel-iconContainer.Mui-completed svg{
    color:#1DB954;
  }

  /* error: FA0D8A */

  span.add{
    cursor: pointer;
  }

  .order-continue{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    /* margin-left: -10px; */
    background-color: #F5F7F9;
    color: #06345e;
    border-top: 1px solid #f5f7f9;
    margin: 10px;
    border-radius: 5px;
  }

  .order-continue h1{
    margin: 0px;
  }


  .tabbox{
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
  }

  .tabbox.tabmenu {
    flex: none;
  }

  .tabcontent {
    flex-grow: 1;
    margin: 0px 10px;
  }


  .MuiTabs-vertical{
    margin-left: 10px;
    width: 60px !important;
    min-width: 60px !important;
    background-color: #F5F7F9;
    border-radius: 5px;
    border-right: 0px solid white !important;
    
  }

  .MuiTabs-vertical button{
    width: 60px !important;
    min-width: 60px !important;
    color:#06345E;
  }

  .navButtons{
    position: absolute;
    right: 0px;
    z-index: 9999;
    margin: 10px;
  }

  .nocolorlink{
    color: inherit;
    text-decoration: none;
  }


  .nocolorlink:hover{
    color:#007bff
  }